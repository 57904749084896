<template>
  <div class="container is-max-desktop is-centered section">
    <div class="has-text-centered">
      <div
        style="
          border-radius: 200px;
          height: 200px;
          width: 200px;
          background: #8c67ef;
          margin: 0 auto;
        "
      >
        <i
          class="checkmark"
          style="
            font-size: 100px;
            color: #ffffff;
            line-height: 200px;
            margin-left: -15px;
          "
          >✓</i
        >
      </div>
      <br />
      <h1>Success</h1>
      <p>
        We received your order request;<br />
        we'll be in touch shortly!
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>